import './App.css';
import React, { useState, useEffect } from 'react';
import { Backdrop, CircularProgress } from '@mui/material'
import NotePadIcon from '../src/images/notepad-icon-dark-grey-static.svg';
import { useForm } from 'react-hook-form';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import AuthProfessor from './services/AuthProfessor';
import Clientes from './Functions/Clientes'
import OrdenarItens from './Functions/OrdenarItens'
import SelectAPI from './Functions/SelectApi';
// import ApiSchool from './services/ApiSettings';

let baseApi = ''
let client = localStorage.getItem('target')

function Login() {
  const [prof, setProf] = useState([{ Codigo: "", Funcionario: "", Polivalente: "0" }])
  const [input, setInput] = useState('');
  const [value, setValue] = useState(0);
  const [ordemClientes, setOrdemClientes] = useState([])
  const [loading, setLoading] = useState(false)
  const [valorEscola, setValorEscola] = useState(localStorage.getItem('escola') !== '' ? localStorage.getItem('escola') : 'escola')
  const [info, setInfo] = useState([])

  const { register, handleSubmit, errors } = useForm()
  const history = useHistory()
  let profSelect = []
  let logado = sessionStorage.getItem('isLoged') ? sessionStorage.getItem('isLoged') : 'falso'


  async function getInfo(baseApi) {
    await Axios.get(`${baseApi}/info`)
      .then((response) => {
        const result = response.data
        setInfo(response.data[0])
        const decimaisNota = isNaN(result[0]?.decimais_nota) || result[0]?.decimais_nota === '0' ? 1 : result[0]?.decimais_nota

        localStorage.setItem('Year', result[0]?.Ano)
        localStorage.setItem('removerDados', result[0]?.ExcluirDados !== undefined ? result[0]?.ExcluirDados : '1')
        localStorage.setItem('decimais_nota', decimaisNota)
      })
      .catch((err) => {
        console.log('Erro ao tentar buscar info: ', err)
      })
  }

  async function getProfessor(escola) {
    baseApi = SelectAPI(escola)

    // console.log('api: ', ApiSchool)

    await Axios.get(`${baseApi}/professores`)
      .then((response) => {
        let professoresPush = []
        let resultado = response.data
        let ordemNomes = resultado.map(item => item.Funcionario).sort()

        for (let valor of ordemNomes) {
          let findProf = resultado.find(item => item.Funcionario === valor)
          professoresPush.push(findProf)
        }

        setProf(professoresPush);
        getInfo(baseApi, client)
      })
  }

  useEffect(() => {
    //Ordenar Escolas (Clientes)
    const Ordem = OrdenarItens(Clientes, 'Nome')
    setOrdemClientes(Ordem)
    //Buscar Professores
    if (prof.length === 0 && valorEscola !== null || prof[0].Codigo === "") {
      baseApi = SelectAPI(valorEscola)
      getProfessor(valorEscola)
    } else {
      baseApi = SelectAPI(valorEscola)
      setValorEscola(localStorage.getItem('escola') !== '' ? localStorage.getItem('escola') : '')
      return
    }
  }, [valorEscola])

  function validate(professores) {
    const filterProfs = professores?.filter(item => item?.Codigo === value)
    return filterProfs
  }

  function storageData(dadosForm, resultadoAuth, infoSchool) {
    if (dadosForm.professores !== '' && resultadoAuth.Status === 'Authenticated' && infoSchool.Status === 'ativo') {
      console.log('profSelect2: ', profSelect)
      sessionStorage.clear()
      sessionStorage.setItem('isLoged', true)
      sessionStorage.setItem('professores', profSelect[0].Codigo)
      sessionStorage.setItem('nome', profSelect[0].Funcionario)
      sessionStorage.setItem('Poli', profSelect[0].Polivalente === 0 ? null : profSelect[0].Polivalente)
      console.log('profSelect3: ', profSelect)
      history.push('/home')
    } else {
      console.log('profSelect4: ', profSelect)
      window.alert('Senha Incorreta!');
      sessionStorage.clear()
    }
  }

  async function AutorizarProf(dadosForm) {
    AuthProfessor(baseApi, dadosForm.professores, input, client)
      .then(response => {
        const resultadoAuth = response
        profSelect = validate(prof)
        console.log('profSelect: ', profSelect)

        console.log('dadosForm: ', dadosForm, ' resultadoAuth: ', resultadoAuth, ' info: ', info)
        storageData(dadosForm, resultadoAuth, info)
      })
      .catch(() => {
        window.alert('Não foi possível efetuar o login!');
      })
  }

  const onSubmit = (dado) => {
    AutorizarProf(dado)
  }

  const onChangeProf = (event) => {
    if (event.target !== undefined) {
      setValue(event.target.value)
    }
  }

  async function onChangeEscola(event) {
    let escola = event.target.value

    setValorEscola(escola)
    setLoading(true)
    localStorage.setItem('escola', escola)

    setTimeout(() => {
      window.location.reload()
    }, 1200)

    getProfessor(valorEscola)
    if (value !== undefined) {
      onChangeProf(value)
    }
  }

  if (logado === 'true') {
    window.location.replace('/home')
    //history.push('/home')
  } else {
    sessionStorage.clear()
    return (
      <div className='container'>

        <header className="header">
          <div className="header-infodat-label">
            <h2>INFODAT</h2>
          </div>
        </header>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <form>
          <div className="professor-form-card">

            {/* <object type="image/svg+xml" data={NotePadIcon} className="icon-svg ">svg-animation</object>  */}
            <img src={NotePadIcon} className="icon-svg " />

            <h1>DIÁRIO</h1>
            <select name="escolas" data-testid="escolas" id="escolas" onChange={onChangeEscola} value={valorEscola} required ref={register}>
              <option value='escola' key='escola' id='escolaOption'>Escola</option>
              {
                ordemClientes.map((val, key) => {
                  return (
                    <option value={val.ClienteValue} key={key}>{val.Nome}</option>
                  )
                })
              }
            </select>
            <br />

            <select name="professores" data-testid="professores" onChange={onChangeProf} onClick={onChangeProf} value={value} id="professores" required ref={register}>
              <option value='' key=''>Professor</option>
              {prof.map((val, key) => {
                return <option value={val.codigo || val.Codigo} key={key}>{val.funcionario || val.Funcionario}</option>
              })}
            </select>

            <br />
            <input className="password" data-testid="password" onChange={e => setInput(e.target.value)} name="senha" type="password" autoComplete='password' placeholder="Senha" ref={register} />

            <div id='PainelAdministrativoLink' >
              <a onClick={() => window.location.replace('/Admin/Login')} >
                Portal administrativo 🔑
              </a>
            </div>

            <br />
            <a data-testid="confirmButton" onClick={handleSubmit(onSubmit)}>
              <Link to={''}>
                <input name="button" className="button" type="submit" value="ENTRAR" required ref={register} />
              </Link>
            </a>


          </div>
        </form>

        <footer></footer>
      </div>
    );
  }
}

export default Login;