import { Protocolo } from "./Protocolo"

export default function SelectAPI(NomeEscola) {
  let baseApi = ''
  switch (NomeEscola) {
    case 'infodat':
      localStorage.setItem('target', 'api')
      baseApi = `http://localhost:3001`
      // baseApi = `https://nathansantos.com.br/Infodat`
      break
    case 'professor samuel':
      baseApi = `${Protocolo}://nathansantos.com.br/ProfSamuel`
      localStorage.setItem('target', 'profsamuel23')
      break
    case 'pequenoAprendiz':
      baseApi = `${Protocolo}://app.nathansantos.com.br/pequenoAprendiz`
      localStorage.setItem('target', 'pequenoAprendiz')
      break
    case 'Paraiso Cultural':
      baseApi = `${Protocolo}://app.nathansantos.com.br/paraisoCultural`
      localStorage.setItem('target', 'paraisoCultural')
      break
    case 'San Raphael':
      baseApi = `${Protocolo}://app.nathansantos.com.br/sanRaphael`
      localStorage.setItem('target', 'sanRafael')
      break
    case 'Barbara Lima':
      baseApi = `${Protocolo}://app.nathansantos.com.br/barbaraLima`
      localStorage.setItem('target', 'barbaraLima')
      break
    case 'Aplicação':
      baseApi = `${Protocolo}://app.nathansantos.com.br/aplicacao`
      localStorage.setItem('target', 'aplicacao')
      break
    case 'Expressivo':
      baseApi = `${Protocolo}://nathansantos.com.br/expressivo`
      localStorage.setItem('target', 'Expressivo')
      break
    case 'Elite':
      baseApi = `${Protocolo}://nathansantos.com.br/Elite`
      localStorage.setItem('target', 'Elite')
      break
    case 'Babylandia':
      baseApi = `${Protocolo}://app.nathansantos.com.br/babylandia`
      localStorage.setItem('target', 'Babylandia')
      break
    case 'Espirito Santo':
      baseApi = `${Protocolo}://nathansantos.com.br/Espiritosanto`
      localStorage.setItem('target', 'Espirito Santo')
      break
    case 'El Shadday':
      baseApi = `${Protocolo}://nathansantos.com.br/ElShadday`
      localStorage.setItem('target', 'El Shadday')
      break
    case 'Magnus':
      baseApi = `${Protocolo}://nathansantos.com.br/Magnus`
      localStorage.setItem('target', 'Magnus')
      break
    case 'SaoLucas':
      baseApi = `${Protocolo}://nathansantos.com.br/SaoLucas`
      localStorage.setItem('target', 'SaoLucas')
      break
    case 'Elic':
      baseApi = `${Protocolo}://app.nathansantos.com.br/elic`
      localStorage.setItem('target', 'Elic')
      break
    case 'Santa Chiara':
      baseApi = `${Protocolo}://app.nathansantos.com.br/santa_Chiara`
      localStorage.setItem('target', 'Santa Chiara')
      break
    case 'Magica do Saber':
      baseApi = `${Protocolo}://nathansantos.com.br/MagicaDoSaber`
      localStorage.setItem('target', 'Magica do Saber')
      break
    case 'Atlantico':
      baseApi = `${Protocolo}://app.nathansantos.com.br/atlantico`
      localStorage.setItem('target', 'Atlantico')
      break
    case 'Inca':
      baseApi = `${Protocolo}://app.nathansantos.com.br/Inca`
      localStorage.setItem('target', 'Inca')
      break
    case 'EscolaMoroni':
      baseApi = `${Protocolo}://diarioweb-infodat.com.br/EscolaMoroni`  // ESCOLA MORONI
      localStorage.setItem('target', 'EscolaMoroni')
      break
    case 'MagicaDoSaber23':
      baseApi = `${Protocolo}://diarioweb-infodat.com.br/magicadosaber23`  // ESCOLA MORONI
      localStorage.setItem('target', 'MagicaDoSaber23')
      break
    case 'SaoJose':
      baseApi = `${Protocolo}://diarioweb-infodat.com.br/SaoJose`  // ESCOLA São José
      localStorage.setItem('target', 'SaoJose')
      break
    case 'Francisco Camerino':
      baseApi = `${Protocolo}://diarioweb-infodat.com.br/FranciscoCamerino`  // ESCOLA Francisco Camerino
      localStorage.setItem('target', 'Francisco Camerino')
      break
    case 'Lavoisier':
      baseApi = `${Protocolo}://socket.insight-se.com.br/lavoisier`  // ESCOLA Lavoisier
      localStorage.setItem('target', 'Lavoisier')
      break
    case 'Michelangelo':
      baseApi = `${Protocolo}://socket.insight-se.com.br/michelangelo`  // ESCOLA Michelangelo
      localStorage.setItem('target', 'Michelangelo')
      break
    case 'Edial':
      baseApi = `${Protocolo}://insight-se.com.br/Edial`  // ESCOLA Edial
      localStorage.setItem('target', 'Edial')
      break
    case 'Amadeus':
      baseApi = `${Protocolo}://nathansantos.com.br/amadeus`  // ESCOLA Amadeus
      localStorage.setItem('target', 'Amadeus')
      break
      case 'Boa Hora':
        baseApi = `${Protocolo}://diarioweb-infodat.com.br/BoaHora`  // ESCOLA Bora Hora
        localStorage.setItem('target', 'Boa Hora')
        break
  }

  return baseApi
}