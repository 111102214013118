export const FormatarTextoConteudoProg = (conteudoProg, conteudoProgChecked) => {
    const conteudoPaste = conteudoProg.filter(item => conteudoProgChecked.includes(item.bncc))
    let conteudoPushStr = ''
    
    for (const item of conteudoPaste) {
        const bncc = item.bncc !== '' ? `(${item.bncc})` : ''
        const addStr = bncc + ' ' + item.conteudo + ';' + '\n'
        conteudoPushStr = conteudoPushStr + addStr
    }
    return conteudoPushStr
}