import Axios from 'axios'

export const PostConteudosProgramaticos = async (baseUrl, curso, disciplina, polivalente) => {
    try {
        const response = await Axios.post(`${baseUrl}/getConteudosProgramaticos`, {
            curso: curso,
            disciplina: disciplina,
            polivalente: polivalente
        }) 

        console.log('response.data', response.data)

        return response.data

    } catch (error) {
        console.log(error)
    }
}