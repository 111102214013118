const Clientes = [
    {
        Nome: 'Professor Samuel',
        ClienteValue: 'professor samuel'
    },
    {
        Nome: 'Pequeno Aprendiz',
        ClienteValue: 'pequenoAprendiz'
    },
    {
        Nome: 'Paraiso Cultural',
        ClienteValue: 'Paraiso Cultural'
    },
    {
        Nome: 'San Raphael',
        ClienteValue: 'San Raphael'
    },
    {
        Nome: 'Barbara Lima',
        ClienteValue: 'Barbara Lima'
    },
    {
        Nome: 'Aplicação',
        ClienteValue: 'Aplicação'
    },
    {
        Nome: 'Infodat',
        ClienteValue: 'infodat'
    },
    {
        Nome: 'Expressivo',
        ClienteValue: 'Expressivo'
    },
    {
        Nome: 'Elite',
        ClienteValue: 'Elite'
    },
    {
        Nome: 'Babylandia',
        ClienteValue: 'Babylandia'
    },
    {
        Nome: 'Espirito Santo',
        ClienteValue: 'Espirito Santo'
    },
    {
        Nome: 'El Shadday',
        ClienteValue: 'El Shadday'
    },
    {
        Nome: 'Magnus',
        ClienteValue: 'Magnus'
    },
    {
        Nome: 'São Lucas',
        ClienteValue: 'SaoLucas'
    },
    {
        Nome: 'Imaculada Conceição',
        ClienteValue: 'Elic'
    },
    {
        Nome: 'Santa Chiara',
        ClienteValue: 'Santa Chiara'
    },
    {
        Nome: 'Magica do Saber',
        ClienteValue: 'Magica do Saber'
    },
    {
        Nome: 'Atlantico',
        ClienteValue: 'Atlantico'
    },
    {
        Nome: 'INCA',
        ClienteValue: 'Inca'
    },
    {
        Nome: 'Escola Moroni',
        ClienteValue: 'EscolaMoroni'
    },
    {
        Nome: 'São José',
        ClienteValue: 'SaoJose'
    },
    {
        Nome: 'Francisco Camerino',
        ClienteValue: 'Francisco Camerino'
    },
    {
        Nome: 'Lavoisier',
        ClienteValue: 'Lavoisier'
    },
    {
        Nome: 'Edial',
        ClienteValue: 'Edial'
    },
    {
        Nome: 'Amadeus',
        ClienteValue: 'Amadeus'
    },
    {
        Nome: 'Boa Hora',
        ClienteValue: 'Boa Hora'
    }
]

module.exports = Clientes