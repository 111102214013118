import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import './ModalConteudoProg.css'

export default function ModalConteudoProg({ modalIsOpen, closeModal, buttonDisable, lancarButtonLoadingClass, lancarButtonText, onChangeCheckBox, conteudoProg, conteudoProgChecked, onConfirmPasteConteudoProg }) {

    return (
        <Modal
            closeTimeoutMS={200}
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel='Arredondar Notas Decimais'
            overlayClassName='modal-overlay'
            className='modal-content-notas-aux' >

            <h2>Conteúdo Programático</h2>

            {
                conteudoProg?.length !== 0 ?
                    <>
                        <table id="TableConteudoProgLancDiario">
                            <tr>
                                <th></th>
                                <th>Curso</th>
                                <th>Disciplina</th>
                                <th>Conteudo</th>
                                <th>BNCC</th>
                            </tr>
                            {
                                conteudoProg?.map((item, index) => {
                                    return <tr id={item.id}>
                                        <td><input type='checkbox' checked={conteudoProgChecked.includes(item.bncc)} onChange={onChangeCheckBox} value={item.bncc} /></td>
                                        <td>{item.curso}</td>
                                        <td>{item.disciplina}</td>
                                        <td>{item.conteudo}</td>
                                        <td>{item.bncc}</td>
                                    </tr>
                                })
                            }
                        </table>
                        <div className="modal-footer">
                            <input type="submit"
                                name="button"
                                className={`button ${lancarButtonLoadingClass}`}
                                id="confirmar"
                                value={`${lancarButtonText}`}
                                onClick={onConfirmPasteConteudoProg}
                                disabled={buttonDisable} />
                                
                            <input
                                type="button"
                                value="CANCELAR"
                                onClick={closeModal}
                                name='button'
                                className='button'
                                id='cancelar' />
                        </div>
                    </>
                    :
                    <h4 style={{ textAlign: 'center', color: '#31313165' }}>Não existe conteúdo programático para essa disciplina</h4>
            }
        </Modal >
    )
}